import logo from '../../../assets/images/logo.png'
import './home.style.scss'

export default function HomeComponent(props: {}){
    
    
    return (
        <div className="container">
           
           <img className='logo-bharath' src={logo} alt="logo" width="100px"/>

           <div className="container">

                <p className="coming-soon-text">
                    site down for maintainance
                </p>

           </div>

        </div>
    );
}