import HttpApi from './api.core';
import { RequestMethod } from './api.core'

export default class LoginApi {

    private http: HttpApi;

    constructor() {
        this.http = new HttpApi("");
    }

    login(username: string, password: string, remember?: boolean) {
        const b = {
            title: 'foo',
            body: 'bar',
            userId: 1,
        };
        const headers = {
            "Accept": "application/json"
        };
        window.sessionStorage.setItem('isLoggedIn','y');
        return this.http.makeHttpRequest(RequestMethod.POST, '/login', headers, b);
    }

    signUp() {
        const body = {
            firstname: "",
            lastname: "",
            birthday: "",
            email: "",
            phone: "",
            pwd: "",
            username: ""
        }



        return this.http.makeHttpRequest(RequestMethod.POST, '/login', null, body);

    }

    isLoggedIn(): boolean {
        return (window.sessionStorage.getItem('isLoggedIn')==='y');
    }

}