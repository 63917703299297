import React from 'react';
import LoginApi from '../api/login.api';

export interface ContextData{
    loginService: LoginApi
}

const ctxValue: ContextData = {
    loginService: new LoginApi()
}

const ApplicationContext = React.createContext<ContextData>(ctxValue);

export default ApplicationContext;