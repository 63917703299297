import React from 'react';
import './App.css';
import ApplicationContext, { ContextData } from './context/ApplicationContext';
import RouteConfig from './routes/route.config';
import {  ROUTES as routes } from './routes/routes';



interface Props {
  history?: any
}

interface State { }

// const navbarExcludedPaths = [
//   APP_LOCATIONS.ForgotPassword,
//   APP_LOCATIONS.Login
// ]

export default class App extends React.Component<Props, State>{

  context: ContextData | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {};
    console.log('App.App()->props', this.props);
  }

  handleChange = (item: any, idx: number) => {
    // this.props.history?.push({
    //   pathname: item.path
    // });
  }

  render() {
    return (
      <>
        {

          <>
            <RouteConfig routes={routes} />
          </>

        }
      </>
    );
  }

}

App.contextType = ApplicationContext;
