import axios, { AxiosInstance, AxiosResponse } from 'axios';


export enum RequestMethod {
    POST = "post",
    GET = "get",
    PUT = "put",
    OPTIONS = "options",
    DELETE = "delete"
}

export default class HttpApi {

    http!: AxiosInstance;

    constructor(baseUrl: string) {
        this.http = axios.create({
            baseURL: baseUrl
        });
    }

    makeHttpRequest(method: RequestMethod, url: string, headers: any, body: any): Promise<AxiosResponse<any>>{
        switch (method) {
            case RequestMethod.POST:
                return this.http.post(url, body, { headers: headers });
            case RequestMethod.PUT:
                return this.http.put(url, body, { headers: headers });
            case RequestMethod.DELETE:
                return this.http.delete(url, { headers: headers });
            case RequestMethod.OPTIONS:
                return this.http.options(url, { headers: headers });
            case RequestMethod.GET:
                return this.http.get(url, { headers: headers });
            default:;
                return this.http.get(url, { headers: headers });
        }

    }

}