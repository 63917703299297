import IRouteDef from "./route-def";
import HomeComponent from "../components/secure/home/home.component";


export enum APP_LOCATIONS {
    LandingPage = "/",
    ForgotPassword = "/forgot-password",
    Home = "/home",
    Schedule = "/schedule/:id",
    Login = "/login",
}

export const ROUTES: IRouteDef[] = [
    {
        path: APP_LOCATIONS.LandingPage,
        component: HomeComponent
    },
    {
        path: '/home',
        component: HomeComponent,
    },
];