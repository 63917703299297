import React from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ContextData } from '../context/ApplicationContext';
import IRouteDef from './route-def';
import _ from 'lodash';


export default class RouteConfig extends React.Component<{ routes: IRouteDef[] }, {}>{

    render() {
        return (
            <Router>
                <Switch>
                    {
                        this.props.routes.map((route, i) => {
                            return route.secure?<ChildRouter key={i} route={route} />:
                            <Route path={route.path} exact={route.path==='/'}
                                render={(p)=>
                                    <route.component {...p} route={route}/>
                                }/>;
                        })
                    }
                </Switch>
            </Router>
        );
    }
}

export class ChildRouter extends React.Component<{ route: IRouteDef }, {}>{

    context: ContextData | undefined;

    componentDidMount() {
        console.log(this.props);
    }

    renderRoute() {
        let canAccess: boolean = true;
        // use the default validator to check against secure
        //canAccess = this.props.route.secure?
        const validatorsCount = _.get(this.props.route, 'validator.length', 0);
        if (canAccess && validatorsCount > 0) {
            this.props.route.validator?.forEach((v, i) => {
                canAccess = canAccess && v.canGoThere();
            });
        }
        let _render = 
            <Route
                path={this.props.route.path}
                exact={this.props.route.path==='/'}
                render={
                    props => canAccess ?
                                this.props.route.redirectTo?
                                <Redirect to={`${this.props.route.redirectTo}`}/>:
                                <this.props.route.component {...props} routes={this.props.route.routes} />
                                :<Redirect to={`${this.props.route.validationFallbackRoute}`}/>
            }
            />;
        return _render;
    }

    render() {
        return (
            <>
                {this.renderRoute()}
            </>
        );
    }
}